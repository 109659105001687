import React from "react";
import Layout from "../layouts/layout.js";
import SEO from "../components/Seo";
import tw from "twin.macro";
import Header from "../components/Header.js";
import { H1, P } from "../utils/helpers.js";

const PageWrapper = tw.section`
  px-4 md:px-8 my-8 max-w-3xl mx-auto
`;

const H3 = tw.h3`
text-xl font-bold my-6
`;

const H4 = tw.h4`
text-lg font-medium italic mt-4 mb-2
`;

export default function Home() {
  return (
    <Layout>
      <SEO title="Privacy Policy" />

      <Header bgImage="https://source.unsplash.com/pPA5ActWLLI/1600x900">
        <H1>Privacy Policy</H1>
      </Header>

      <PageWrapper>
        <P>
          <strong>Last modified and effective: Mar 22, 2024</strong>
        </P>

        <H3>1. Privacy Notice</H3>
        <P>
          Jeevz’s core mission is to make life’s logistics easier by providing
          drivers to drive you in your own vehicle or a vehicle you provide.
          Your information may be shared with other Jeevz Services of which you
          are not a user, as well as potentially transmitted to third parties as
          set forth herein. Jeevz is committed to protecting the privacy of its
          users and the information that You share in connection with your use
          of Jeevz’s Services. This Privacy Policy (the “Policy”) describes how
          we collect, use, share, transfer, and disclose your personal
          information. This Privacy Policy applies to information we collect
          when you use our websites, mobile applications, and other online
          products and services where this Policy is posted (collectively, the
          “Services”). Jeevz may provide additional notices through our Services
          in addition to these practices or provide you additional choices about
          how Jeevz may process your Personal Information. If you have any
          questions about this Policy, please contact us at{" "}
          <a href="mailto:support@jeevz.com">support@jeevz.com</a>.
        </P>
        <P>
          By submitting information, including Personal Information, through our
          Services, you agree to the terms of this Privacy Policy, as well as
          our Data Processing Agreement, Cookie Policy, and any other
          documentation referenced herein. If you do not agree, you should
          discontinue use of the Services immediately.
        </P>
        <P>
          We may change the provisions of this Policy at any time and will
          indicate when changes have been made by revising the date at the top
          of this Policy. We encourage you to review the Policy whenever you
          access the Services to make sure that you understand our information
          collection, use, and disclosure practices. When we make material
          changes to this Policy, we will provide you with prominent notice as
          appropriate under the circumstances, e.g., by displaying a prominent
          notice within the Service or by sending you an email. We may notify
          you in advance.
        </P>
        <H3>2. Information That We Collect and How We Use It</H3>
        <H4>2.1. Information That You Provide to Us</H4>
        <P>
          We will collect information that you provide to us when you use the Services, such as when you (1) create an account; (2) make or respond to a purchase (including address, precise geolocation, or other location information in connection with requesting a driver or ride through the Services or responding to such requests); (3) participate in events or promotions; (4) send questions or comments via the Service, email, or chat to Jeevz or other users; (5) fill out surveys; or (6) otherwise communicate with us or other users through or about the Services. We will also collect information when you sign up for marketing emails, events, or other opportunities through Jeevz. Information collected may contain personal information such as your name, email address, billing and contact information, and other identifying information you may provide to Jeevz (“Personal Information”). Aggregated, non-personally identifying information is not your personal information. We may use aggregated, non-personally identifying information to operate, analyze, improve, and optimize our website and service.
        </P>
        <H4>2.2. Information We Collect About Your Use of Our Services</H4>
        <P>
          Jeevz automatically records certain information about your use of the Services. Jeevz uses a variety of technologies to collect this information, including but not limited to persistent and session cookies, web beacons, and pixel tracking technology. The information we collect includes number of log-ins, actions taken, data displayed or clicked on (e.g., UI elements, links), other login information (e.g., browser type, IP address, date and time of access, cookie ID, referrer URL, etc.), and precise geolocation data when you authorize our mobile application to access your device’s location. We use this information to analyze trends, administer the Service, track users’ movements around the Service, gather demographic information about our user base as a whole, and better tailor our Services to our users’ needs. For example, we use “Pixel Tags” in the provision of our Services to you. Pixel Tags are tiny graphic images with a unique identifier that are used to track online movements of web users. Pixel Tags are embedded invisibly in Web pages. Pixel Tags also allow us to send email messages in a format users can read, and they tell us whether emails have been opened to ensure that we are sending only messages that are of interest to our users. We also use analytics tools to collect information regarding your service usage to improve our Services.
        </P>
        <H4>2.3. Information We Collect from Third Parties</H4>
        <P>
          Any Personal Information that Jeevz may obtain from other third-party
          sources will be processed by Jeevz in accordance with this Policy and
          all applicable laws. We may add this information to the information we
          have already collected from you via our Services in order to improve
          the Services we provide to you.
        </P>
        <H4>2.4. How We Use The Information We Collect</H4>
        <P>
          We use your Personal Information in connection with providing you with
          the Services and product features. Here are some examples of how we
          use your information:
        </P>
        <P>To provide, develop, and enhance the Service;</P>
        <P>
          Provide and deliver the products and services you request including by connecting drivers and riders, process
          transactions, and to send you related information including
          confirmations and invoices;
        </P>
        <P>To respond to feedback and monitor security of the Service;</P>
        <P>
          To provide users with promotional communications, materials, and other
          third-party information;
        </P>
        <P>
          To enforce our terms or rights under any agreement between you and
          Jeevz; and,
        </P>
        <P>
          To perform any other action deemed necessary by Jeevz in its provision
          of the Service, interactions with you, or any other third party.
        </P>
        <P>
          We may also store information locally on your device, for instance in
          your local cache. Jeevz reserves the right to review any information
          to help resolve problems with our Services or to ensure compliance
          with our Terms of Service.
        </P>
        <H3>
          3. The Legal Basis on Which Jeevz Collects Your Personal Information
        </H3>
        <P>
          Jeevz collects and processes your Personal Information on the basis of
          different legal grounds, depending on the nature of the Personal
          Information being provided and the type of processing involved, as
          follows:
        </P>
        <H4>3.1. Performance of a Contract</H4>
        <P>
          Some of the Personal Information processed by Jeevz is performed on
          the basis that it is necessary for the performance of our agreement
          with you, or in order to take steps at the request of the user prior
          to entering such an agreement.
        </P>
        <H4>3.2. Legitimate Interest</H4>
        <P>
          A second ground relied upon by Jeevz for other types of processing of
          your Personal Information is that it is necessary for the purposes of
          legitimate interests pursued by Jeevz. Such legitimate interests will
          include where Jeevz sends you marketing about our products and
          services, where we believe you have a reasonable expectation that we
          will perform a particular type of processing on your behalf, or where
          such processing is strictly necessary for fraud detection and
          prevention. Jeevz will only rely on such a ground where an assessment
          has been performed balancing the interests and rights involved and the
          necessity of the processing in order to provide our services, products
          and features to you.
        </P>
        <H4>3.3. Compliance with a Legal Obligation</H4>
        <P>
          A third ground relied upon for certain types of processing is that it
          is necessary in order to allow Jeevz to comply with a legal
          obligation. An example of this would be where Jeevz is required to
          retain business records for fixed periods of time in order to comply
          with local legal requirements.
        </P>
        <H4>3.4. Consent</H4>
        <P>
          Finally, in certain limited situations, Jeevz relies on your consent
          in order to process your Personal Information. Where Jeevz requires
          your consent in order to collect and process certain Personal
          Information, we seek your consent at the time of provision, and such
          processing will only be performed where consent is secured. For
          example, if you no longer want to receive marketing materials from us
          based upon your Personal Information, there are a number of options
          available to you. If you wish to opt out of a particular marketing
          email from Jeevz, you may do so by clicking “unsubscribe” (or an
          equivalent action) where applicable. Please note that this will only
          opt you out of that particular marketing campaign. You can also access
          and adjust your marketing communications subscriptions from your
          account dashboard or contact support@jeevz.com to do so. We will
          comply with your request as soon as reasonably practicable, and in
          accordance with applicable laws.
        </P>
        <H3>4. Sharing Your Information</H3>
        <P>We may share your information as follows:</P>
        <P>
          <strong>With Other Users. </strong>We may share information about you,
          your interactions with the Service, Content you post, or other similar
          information with other users of our Services.
        </P>
        <P>
          <strong>With Other Jeevz Services. </strong>We may share information
          about you, your interactions with the Service, Content you post, or
          other similar information with other Jeevz Services, including those
          of which you are not a user.
        </P>
        <P>
          With Third Parties. We may share information about you with third
          parties, whether designated by you, or with other third parties
          including our service providers, business partners, and other
          organizations as we may choose.
        </P>
        <P>
          <strong>With Service Providers.</strong> We may share your Personal
          Information with third parties, such as vendors and service providers
          who provide assistance with billing, payment processing, marketing,
          data analytics support and service associated with our Services,
          including any provision of goods through our Services.
        </P>
        <P>
          <strong>With Business Partners and Affiliates.</strong> We may share
          your Personal Information with our channel partners, subsidiaries,
          affiliates, join ventures, or any other company, to fulfill
          obligations and requests, to deliver support, and to provide users and
          our customers’ users with information about Jeevz. Jeevz does not
          control how a business partner may use your Personal Information. Our
          business partners are responsible for managing the use of Personal
          information collected in these circumstances.
        </P>
        <P>
          <strong>
            For Compliance with laws and for other legitimate business purposes.
          </strong>{" "}
          We may share your Personal Information when we believe disclosure is
          necessary or required by law, due to corporate restructuring, change
          of control, regulation or regulatory action, to protect users, the
          integrity of the Service, and to defend or exercise our legal rights.
          We may also disclose your Personal Information when it may be
          necessary for other legitimate purposes as reasonably determined by
          Jeevz.
        </P>
        <P>
          <strong>And Generally.</strong> We may share information with the
          general public, such as your name, username, profile picture, and any
          Content you post.
        </P>
        <H3>5. Email Communication</H3>
        <P>
          We may send you emails, including marketing, promotional, and
          third-party related communications. You may opt out of these emails at
          any time by unsubscribing or selecting this option through our
          Services. Note that You may need to opt out from each Jeevz Service
          individually. Despite your election, we may still be required to send
          you Service-related communications, including any updates to our terms
          of service or this Policy.
        </P>
        <H3>6. SMS Communication</H3>
        <P>
          By signing up you agree to Jeevz's <a href="/terms">Terms of Service</a> and this Privacy Policy, which outline your rights and obligations with respect to your use of the Service and processing of your data.
        </P>
        <P>You agree to receive subsequent email and SMS communications, which you may opt out of, or unsubscribe from, at any time.</P>
        <P>Jeevz is committed to protecting your data privacy. Permissions are part of our continuing compliance efforts.</P>
        <P>We will provide reasonable accommodations with regard to use of the Jeevz mobile application. If you require an accommodation while using our site, please contact <a href="mailto:support@jeevz.com">support@jeevz.com</a> to request assistance.</P>
        <H3>7. Public Profile</H3>
        <P>
          As an essential element of the Service, most of the Personal
          Information you provide to us when you register or update your profile
          is displayed on your profile and intended to be shared with other
          users. Your photos, posts, and other content you post to the site are
          also meant for public consumption. We may display this content on the
          Site, our Services, and further distribute it to a wider audience
          through third party sites and services. Once displayed on publicly
          viewable web pages, that information can be collected and used by
          others. We cannot control who reads your postings or what other users
          may do with the information that you voluntarily post, so it is very
          important that you do not put any sensitive, confidential, or any
          other information you do not wish to be shared in your posts. Once you
          have posted information publicly, while you will still be able to edit
          and delete it on the Service, you will not be able to edit or delete
          such information cached, collected, and stored elsewhere by others.
        </P>
        <H3>8. Cookies and Flash LSOs</H3>
        <P>
          You may instruct your browser to stop accepting cookies at any time or
          set your browser to prompt you before accepting cookies. Please
          consult your browser’s settings for further technical information. If
          you do not accept cookies, you may not be able to use all portions or
          functionality of the Service. We may, or our third parties may, use
          “Flash Cookies,” to store your preferences for volume control or to
          personalize certain video features. Flash Cookies are different from
          browser Cookies because of the amount and type of data and how the
          data is stored. Cookie management tools provided by your browser will
          not remove Flash Cookies.
        </P>
        <H3>9. Information From Social Networking Sites</H3>
        <P>
          If You provide information through, or sign in via, a social
          networking site such as facebook, google, or any other site we
          support, we may receive information from that site. Any information we
          receive may be dependent on the privacy settings you have with that
          site, so please consult that site’s privacy and data practices. We
          will not be responsible for any practices of that social networking
          site. You may de-link your social networking site account from Jeevz
          through Your Jeevz account, and may also change your sharing settings
          through such social networking site.
        </P>
        <H3>10. Contact Lists</H3>
        <P>
          You may upload or import your contacts to the Jeevz Service. When you
          upload these contacts, you agree you have the rights to upload such
          contacts and share these contacts with Jeevz. We rely upon you to
          obtain whatever consents from the relevant third parties that may be
          required by law to allow us to access and upload the third parties’
          names, e-mail addresses, and other contact information. You or such a
          third party may contact us at{" "}
          <a href="mailto:support@jeevz.com">support@jeevz.com</a> to request
          the removal of this information from our database.
        </P>
        <H3>11. Feedback</H3>
        <P>
          We may publish any feedback we receive from you. We may identify you
          by your first and last name and may also indicate your home city. We
          may post any feedback on the Service from time to time. If you make
          any comments on a blog or forum associated with Jeevz, you should be
          aware that you have no reasonable expectation of privacy and that any
          Personal Information you submit there can be read, collected, or used
          by other users of these forums, and could be used to send you
          unsolicited messages. We are not responsible for the personally
          identifiable information you choose to submit in these blogs and
          forums.
        </P>
        <H3>12. Retention, Modification, or Deletion of Your Information</H3>
        <P>
          You may change any of Your Personal Information in Your Account by
          editing Your profile within Your Account. Generally, Jeevz will retain
          user personal information for as long as your account is active or as
          needed to provide you Services. We may retain certain user Personal
          Information indefinitely, unless you delete it or request its
          deletion. For example, we don’t automatically delete inactive user
          accounts, so unless you choose to delete your account, we will retain
          your account information indefinitely or as long as allowable by law,
          whichever comes first.
        </P>
        <P>
          If you would like to cancel your account or delete your user Personal
          Information, you may do so in your user account, but we may retain and
          use your information as necessary to comply
        </P>
        <P>
          with our legal obligations, resolve disputes, and enforce our
          agreements before any such deletion. After an account has been
          deleted, certain data, such as messages to other users and comments,
          will remain. However, we will delete or de identify your Personal
          Information, including your user name and email address, by
          associating them with an anonymous user. When we delete any
          information, it will be deleted from the active database, but may
          remain in our archives.
        </P>
        <H3>13. Rights of EEA Users (or Similar International Areas)</H3>
        <P>
          Information, including information collected in the European Economic
          Area ("EEA") may be transferred, stored and processed by us and our
          service providers in the United States and other countries whose data
          protection laws may be different than the laws of your country. We
          will protect your Personal Information in accordance with this Privacy
          Notice wherever it is processed and take appropriate steps to protect
          the information in accordance with applicable laws. These steps
          include obligations to both Jeevz and you, which are set forth in our
          Data Processing Agreement, which your use of the Service is subject
          to, and which can be reviewed here. If you live in the European
          Economic Area, or a similar international area, you may have
          additional privacy rights available to you under applicable laws. Your
          rights may include: (1) Right not to provide or withdraw consent at
          any time (2) Right to access the Personal Information that You
          provided Us (3) Right to erasure Personal Information that We hold
          about You (4) Right to object to the processing of Your Personal
          Information (5) Right to opt out of marketing communications and (6)
          Right to correct any of Your Personal Information.
        </P>
        <H3>14. California Privacy Disclosure</H3>
        <H4>14.1. Your California Privacy Rights</H4>
        <P>
          By availing yourself of the rights afforded in this section of our
          Privacy Policy, you are declaring that you are a California resident.
        </P>
        <P>
          Other residents: You may make a verified request to Jeevz regarding
          your Personal Information. While Jeevz does not sell your Personal
          Information, you can nonetheless make a formal request to delete your
          Personal Information held by Jeevz through your account or, if you are
          unable to access your account, via email at{" "}
          <a href="mailto:support@jeevz.com">support@jeevz.com</a>.
        </P>
        <H4>14.2. The Right to Know Your Personal Information</H4>
        <P>
          You have the right to request that we disclose the Personal
          Information we collect, use, disclose and sell about you. Section 2 of
          this Policy explains the categories of Personal Information we
          collected over the past twelve (12) months, why we collected this
          information, and the third parties with which we shared it.
        </P>
        <H4>
          14.3. The Right to Opt-out of the Sale of Your Personal Information
        </H4>
        <P>
          We do not sell your Personal Information. California’s “Shine the
          Light” law (Civil Code section 1798.83) also permits California
          residents once per calendar year to request and receive information
          about a business’ disclosure of certain categories of Personal
          Information to other companies for direct marketing purposes. If you
          are a California resident and a user of our Service, you can request a
          copy of this information from Jeevz by sending an email to{" "}
          <a href="mailto:support@jeevz.com">support@jeevz.com</a>. Please
          include your name and email address with your request. Jeevz reserves
          the right to request additional Personal Information from you to
          confirm your identity before responding to this request.
        </P>
        <H4>
          14.4. The Right to Non-Discrimination for Exercising Your Privacy
          Rights
        </H4>
        <P>
          We will not discriminate against you for exercising any of your rights
          under the CCPA.
        </P>
        <H3>15. Data Protection Officer</H3>
        <P>
          Jeevz has appointed a Data Protection Officer to ensure continued
          commitment to privacy and security practices. For further information
          please contact Us at:{" "}
          <a href="mailto:support@jeevz.com">support@jeevz.com</a>.
        </P>
        <H3>16. Third Party Services</H3>
        <P>
          The Services may contain links to third party websites and services.
          When you click on a link to any other website, location, or service
          You will leave our Services and go to another site or service and
          another entity may collect data from You. We do not control and are
          not responsible for such third party websites or services. Third party
          services are for your convenience only, and we do not recommend,
          screen, review, or have any control over the actions taken by these
          third parties. Your use of these services is solely your choice and
          decision. Please review every third-party website or service You visit
          or use, including those that you interact with through Our Services.
        </P>
        <H3>17. Your Third Parties</H3>
        <P>
          If you decide to invite a third party to create an Account, we will
          collect your and the third party’s names and e-mail addresses, as well
          as any other relevant information in order to send e-mail and other
          communications to the third party. We may maintain this information
          indefinitely in our database and may also use this information to help
          us establish networks of contacts, document relationships, facilitate
          referrals, and improve our Services. We may also use this information
          to show you and other users that you may know each other or have
          shared contacts. We rely upon you to obtain whatever consents from the
          third party that may be required by law to allow us to access and
          upload the third party’s names and e-mail addresses as required above.
        </P>
        <H3>18. Security</H3>
        <P>
          Jeevz is committed to protecting the security of your Personal
          Information. We use a variety of industry-standard security
          technologies and procedures to help protect your Personal Information
          from unauthorized access, use, or disclosure. We also require You to
          enter a password to access your Account information. Please do not
          disclose your Account password to unauthorized people. You are solely
          responsible for all actions or disclosures of your information or any
          access to your Account. No method of transmission over the Internet,
          or method of electronic storage, is 100% secure, however. Therefore,
          while Jeevz uses reasonable efforts to safeguard your Personal
          Information, Jeevz cannot guarantee its absolute security.
        </P>
        <H3>19. A Note About Children</H3>
        <P>
          We do not intentionally gather Personal Information from individuals
          who are under the age of 13. If a child under 13 submits Personal
          Information to Jeevz and we learn that the Personal Information is the
          information of a child under 13, we will attempt to delete the
          information as soon as possible. If you believe that we might have any
          Personal Information from a child under 13, please contact Us at{" "}
          <a href="mailto:support@jeevz.com">support@jeevz.com</a>.
        </P>
        <H3>20. Changes To this Privacy Policy</H3>
        <P>
          We may update this Privacy Policy from time to time and for any
          reason. We will endeavor to provide you advanced notice of such
          updates, but you are ultimately responsible for consulting this
          Privacy Policy regularly for any changes. Continued use of our
          Services shall indicate your acknowledgement of such changes and
          agreement to be bound by the terms of such changes. If you do not
          agree, you should immediately discontinue your use of the Services.
        </P>
      </PageWrapper>
    </Layout>
  );
}
